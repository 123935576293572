<template>
    <div class="container">
        <div class="right_cont">
            <!-- <div class="title flex_center_start_box">
                <div class="title_txt flex_center">
                    <img src="../../assets/img/detail/title_ic4.png" />
                    HISTORY AND EVENTS
                </div>
            </div> -->
            <!-- 只有公司有 -->
            <div class="his_box">
                <div class="head-title">Names</div>
               <div class="borde">
                <div>
                    <ul v-if="$route.query.type == 'Person'" class="name-box-head">
                        <li class="information-box-text persion-table-text">First Name</li>
                        <li class="information-box-text persion-table-text">Middle Name</li>
                        <li class="hinformation-box-text persion-table-text">Surname</li>

                        <li class="information-box-text persion-table-text line_1 tbl_company">Original Script Name </li>
                        <li class="information-box-text persion-table-text">Language</li>
                    </ul>
                    <ul v-else class="name-box-head">
                        <li class="information-box-text persion-table-text1">Name</li>

                        <li class="information-box-text persion-table-text1">suffix</li>
                        <li class="information-box-text persion-table-text1">Original Script Name</li>
                        <li class="information-box-text persion-table-text1">Language</li>
                    </ul>
                </div>
                <div class="head-title" style="padding-left: 10px;">Primary Name</div>
                <!-- <div class="information-box-text" style="margin-top: 20px">{{ PrimaryNam.name }}</div> -->
                <div class="persion-table">
                    <el-table ref="treeTable" v-if="zhicaiTable1" :data="zhicaiTable1" row-key="aaa_id" :show-header="false" style="width: 100%">
                        <!-- <el-table-column label="TITLE" prop="TITLE" align="left" show-overflow-tooltip :resizable="false" class-name="company company_link yangsi"></el-table-column> -->
                        <el-table-column label="FIRSTNAME" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div v-if="$route.query.type == 'Person'" class="tbl_company line_1">
                                    {{ scope.row.first_name }}
                                </div>
                                <div v-else class="tbl_company line_1">
                                    {{ scope.row.name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="$route.query.type == 'Person'" label="MIDDLE NAME" :prop="showTranslate == true ? 'source' : 'middle_name'" align="left" show-overflow-tooltip :resizable="false"></el-table-column>

                        <el-table-column label="surname" :prop="showTranslate == true ? 'surname' : 'surname'" align="left" show-overflow-tooltip :resizable="false" />

                        <!-- <el-table-column label="Gender" :prop="showTranslate == true ? 'summary' : ''" align="left" show-overflow-tooltip :resizable="false" /> -->
                        <el-table-column label="orig" :prop="showTranslate == true ? 'summary' : ''" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div class="line_1" v-for="(item, index) in scope.row.associated_names?.original_script_names" :key="index">{{ item.name }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="lenge" :prop="showTranslate == true ? 'summary' : ''" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div class="line_1" v-for="(item, index) in scope.row.associated_names?.original_script_names" :key="index">{{ item.language }}</div>
                            </template>
                        </el-table-column>
                        <!-- 空数据状态的插槽 -->
                        <template slot="empty">
                            <div style="text-align: left">No Date</div>
                        </template>
                    </el-table>
                </div>
                <div class="head-title" v-if="Formerlyable2.length > 0" style="padding-left: 10px;">Also Known As</div>
                <!-- <div class="information-box-text" style="margin-top: 20px">{{ PrimaryNam.name }}</div> -->
                <div class="persion-table">
                    <el-table ref="treeTable" v-if="Formerlyable2.length > 0" :data="Formerlyable2" row-key="aaa_id" :show-header="false" style="width: 100%">
                        <!-- <el-table-column label="TITLE" prop="TITLE" align="left" show-overflow-tooltip :resizable="false" class-name="company company_link yangsi"></el-table-column> -->
                        <el-table-column label="FIRSTNAME" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div v-if="$route.query.type == 'Person'" class="tbl_company line_1">
                                    {{ scope.row.first_name }}
                                </div>
                                <div v-else class="tbl_company line_1">
                                    {{ scope.row.name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="$route.query.type == 'Person'" label="MIDDLE NAME" :prop="showTranslate == true ? 'source' : 'middle_name'" align="left" show-overflow-tooltip :resizable="false"></el-table-column>

                        <el-table-column label="surname" :prop="showTranslate == true ? 'surname' : 'suffix'" align="left" show-overflow-tooltip :resizable="false" />

                        <!-- <el-table-column label="Gender" :prop="showTranslate == true ? 'summary' : ''" align="left" show-overflow-tooltip :resizable="false" /> -->
                        <el-table-column label="orig" :prop="showTranslate == true ? 'summary' : ''" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div class="line_1" v-for="(item, index) in scope.row?.associated_names?.original_script_names" :key="index">{{ item.name }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="lenge" :prop="showTranslate == true ? 'summary' : ''" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div class="line_1" v-for="(item, index) in scope.row?.associated_names?.original_script_names" :key="index">{{ item.language }}</div>
                            </template>
                        </el-table-column>
                        <!-- 空数据状态的插槽 -->
                        <template slot="empty">
                            <div style="text-align: left">No Date</div>
                        </template>
                    </el-table>
                </div>
               </div>
            </div>
        </div>
    </div>
</template>
  <script>
import { searchDetailsById } from '@/api/searchApi.js';
import Page from '@/components/page';

export default {
    // components: { Page },
    data() {
        return {
            page1: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            activities: [],
            Numbersable: [],
            tableListnum: [],
            parentTablenum1: [],
            watchlistothertable: [],
            showTranslate: false,
            watchlisrole: { primary: [] },
            Formerlyable2: [],
            zhicaiTable1: [],
        };
    },
    computed: {
        headerCellStyle() {
            return {
                backgroundColor: 'red', // 设置背景色
                // 其他样式属性
            };
        },
    },

    created() {
        this.getdetail();
    },
    methods: {
        pageListid() {
            // console.log(this.parentTable);

            this.watchlistothertable = this.parentTablenum1.filter((item, index) => index < this.page1.pageNo * this.page1.pageSize && index >= this.page1.pageSize * (this.page1.pageNo - 1));
            this.page1.total = this.parentTablenum1.length;
        },
        onPageChangeList1(pageNo) {
            // this.pageNo += 1
            this.page1.pageNo = pageNo;
            this.pageListid();
        },
        currtentPageChange1(pageSize) {
            // this.pageNo += 1
            this.page1.pageSize = pageSize;
            // this.init()
            this.pageListid();
        },

        getdetail() {
            searchDetailsById({ id: this.$route.query.id }).then(res => {
                console.log(`res`, res);

                // this.tableListnum = res.data.attributes.entity?.identification_details ? res.data.attributes.entity?.identification_details.concat(nums) : [];

                this.PrimaryNam = res.data.attributes.basic.name_details.primary_name;
                this.zhicaiTable1 = [res.data.attributes.basic.name_details.primary_name];
                console.log(``, this.zhicaiTable1);
                // 别名
                this.Formerlyable2 = res.data.attributes.basic?.name_details.also_known_as ? res.data.attributes.basic?.name_details.also_known_as : [];
                // 前称
                this.Formerlyable1 = res.data.attributes.basic?.name_details?.formerly_known_as ? res.data.attributes.basic?.name_details?.formerly_known_as : [];
                this.Formerlyable = res.data.attributes.basic?.name_details?.also_known_as ? res.data.attributes.basic.name_details.also_known_as[0].associated_names : [];
            });
        },
        setid(row) {
            console.log(`row`, row.sources[0]);
            if (row.sources[0].includes('http')) {
                console.log(`true`, true);
                let routeData = row.sources[0];
                window.open(routeData, '_blank');
            } else {
                let routeData = 'http://' + row.sources[0];
                window.open(routeData, '_blank');
            }
            // let routeData = row.url;
            // window.open(routeData, '_blank');
        },
    },
};
</script>
  <style scoped>
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}
.right_cont {
    margin-top: 60px;
}
.title {
    margin-bottom: 20px;
}
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}
.title_txt img {
    width: 20px;
    margin-right: 8px;
}
.pageBox {
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
}
.his_box {
    width: 100%;
    padding: 30px 30px 30px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px 14px 0px 0px;
}
.his_box /deep/ .el-timeline-item__wrapper {
    display: flex;
    padding-left: 14px;
}
.his_box /deep/ .el-timeline-item__node--normal {
    width: 8px;
    height: 8px;
    left: 1px;
}
.his_box /deep/ .el-timeline-item__node {
    background-color: #ff7600;
}
.his_box /deep/ .el-timeline-item__tail {
    border-left: 2px dashed #8497ab;
}
.his_box /deep/ .el-timeline-item__timestamp.is-top {
    font-size: 16px;
    color: #8497ab;
    padding-top: 0;
}
.his_box /deep/ .el-timeline-item__content {
    padding-bottom: 24px;
    font-size: 16px;
    color: #022955;
    border-bottom: 1px solid #e7eaed;
    margin-left: 20px;
    flex: 1;
    line-height: 26px;
    margin-top: -5px;
}
.head-title {
    /* color: #022955; */
    font-size: 16px;
    line-height: 35px;
    /* margin-top: 20px; */
    color: #ff7600;
    /* padding-left: 20px; */
    font-weight: 700;
    font-family: 'element-icons';
}
.his_box /deep/ .el-table th.el-table__cell {
    background: #f2f5f9;
}
.name-box-head {
    display: flex;
    /* justify-content: space-between; */
    height: 50px;
    line-height: 40px;
    font-size: 14px;
    background: #F2F5F9;
    align-items: center;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}
.persion-table {
}
.persion-table-text {
    width: -webkit-fill-available;
    /* width: 260px; */
    padding-left: 10px;
    /* margin-right: 120px; */
}
.persion-table-text1 {
    width: -webkit-fill-available;
    padding-left: 10px;
    /* margin-right: 160px; */
}
.borde{
  border: 1px solid #EBEEF5;
  border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    border-bottom: none;
}
</style>
  